
































import {
    Component,
    Vue
} from 'vue-property-decorator';
 

import {
    Auth
} from '@/store/auth'
import {
    Core
} from '@/store/core'
import {
    User
} from '@/store/user'

@Component({
    components: {
         
    },
})
export default class Home extends Vue {
    private dashboard: any = null
    private user: any = {}
    private years: any = []
    private response: boolean = false
$vs:any
    public async created() {
        this.user = await User.getUser();
        this.dashboard = await Core.getHttp(`/api/ita/v2/dashboard/${this.user.ext_link.id}/`)
        this.years = await Core.getHttp(`/api/iit/v1/year`)
        await this.autoGoToCheck()
        this.response = true
    }

    private async autoGoToCheck(){
        console.log(this.years);
        let currentYears = this.years[(this.years.length)-1]
        if(this.$route.query.auto){ 
            await this.getIssue(currentYears.id,currentYears.status)
        }
    }

    public async getIssue(yearId: number, status: boolean) {
        //this.$router.push(`detail?year=${yearId}`)
        if (!status) {
            let userInAnswer = await Core.getHttp(`/api/iit/v2/ansewer/user/?user=${this.user.pk}&year=${yearId}`) 
            console.log(userInAnswer);
            if (userInAnswer.length > 0) {
                await this.openNotification('top-right', 'primary', `<i class="em em-female-teacher" aria-role="presentation" aria-label=""></i>`,'คุณได้ทำการประเมินแล้ว','ระบบอนุญาตให้ประเมินได้ครั้งเดียว')
            } else {
                await this.$router.push(`/iit/detail?year=${yearId}`)
            }
          //   await this.$router.push(`detail?year=${yearId}`)
        } else {
          await this.openNotification('top-right', '#D65B6D', `<i class="em em-lock" aria-role="presentation" aria-label="LOCK"></i>`,'ปิดการให้ประเมิน','ไม่สามารถประเมินได้ เนื่องจากระบบปิดการให้ประเมินแล้ว กรุณาติดต่อผู้ดูแลระบบ')
        } 
    }

    async openNotification(position:any = null, color:any, icon:any,title:string,text:string) {
     
          const noti = this.$vs.notification({
            icon,
            color,
            position,
            title: title,
            text: text
          })
    }

}
